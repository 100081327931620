import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal"
import Title from "../../core/H3"
import P from "../../core/P"

const HireAppConsultant = ({ title, subtitle, action1, image }) => {
  //remove after gitex2022 start
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  //remove after gitex2022 end
  return (
    <div className="bg-transparent w-11/12 sm:w-4/5 mx-auto rounded-2xl ">
      {openGitex && <GitexPopupModal open={openGitex} closeGitexModal={closeGitexModal} url={"https://meetings.hubspot.com/divyaa1"} />}
      <div className="grid bg-white  grid-cols-12  rounded-xl sm:rounded-xl sm:shadow-xl my-16 py-5 lg:py-12 md:py-10  justify-center md:gap-4 items-center">
        <div className="col-span-12 lg:col-span-5 flex justify-end" >
          <div className="h-full w-full px-5 md:px-10 lg:pl-10">
            {image && (
              <img
                src={image?.mediaItemUrl}
                alt={image?.altText ? image.altText : "Contact Us"}
                width={image.mediaDetails.width}
                height={image.mediaDetails.height}
                // title="Tech Section"
                className="w-full h-full object-cover"
                loading="lazy"
              />
            )}
          </div>
        </div>
        <div className="col-span-12 mt-4 lg:mt-0 lg:col-span-7  px-2 lg:px-4 md:pb-0 pb-10" >
          {title && (
            <Title
              title={title}
              className="max-w-4xl text-center text-shark-500 lg:text-left mx-auto"
            />
          )}
          {subtitle && (
            <P
              title={subtitle}
              className="text-shark-400 text-center lg:text-left"
            />
          )}
          {action1 && action1.url.charAt(0) === "#" ?
            <div className="flex flex-row justify-center lg:justify-start">
              <button
                className="mt-6 bg-blue-500 inline-flex px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded cursor-pointer"
                onClick={openGitexModal} //remove after gitex2022
              >
                {action1.title}
              </button>
            </div>
            :
            <div className="flex flex-row justify-center lg:justify-start">
              <a
                href={action1.url}
                className="mt-6 bg-blue-500 inline-flex px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                onClick={openGitexModal} //remove after gitex2022
              >
                {action1.title}
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default HireAppConsultant
